import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://ucatolicamembresias.test.solunes.com/',
    type: 'ucatolica',
    title: 'Club Deportivo Universidad Católica',
    subtitle: 'Membresías',
    description: `"Club Deportivo Universidad Católica - Membresías" es un sitio en el que puedes administrar tus membresías, como las de socios, entradas y abonos.`,
    keywords: 'club, Club Deportivo Universidad Católica, campeon, Club Deportivo Universidad Católica, membresias, socios, abonos, entradas, tickets',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.svg',
    imgLogoBig: '/assets/img/logo-big.png',
    templateType: 'template-1',
    theme: 'light',
    changeTheme: false,

    dashboard: false,
    disclaimer: false,
    header: true,
    sidebar: true,
    footer: true,
    preFooter: true,

    cart: false,

    authType: 'auth-1',
    authGoogle: true,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'home',

    processCartType: 'type-1',
    processCartTitle: '',

    multiplePayments: true,
    multipleProfiles: true,

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    //* Firebase
    firebase: {
        apiKey: "AIzaSyCPMg-2WiXoWDelgiCzhw1KcAhluY5u7PQ",
        authDomain: "club-colo-colo.firebaseapp.com",
        projectId: "club-colo-colo",
        storageBucket: "club-colo-colo.appspot.com",
        messagingSenderId: "256414109102",
        appId: "1:256414109102:web:b9f83c7eb1e3c4b4158c3f",
        measurementId: "G-LQHMW2CJJS"
    },

    keyEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',
    ivEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',

    country: 'cl',
};
